<template>
	<div class="popup_sub_container">
		<h2>VIEW / EDIT USER DETAILS</h2>
		<i class="closeme" @click="closeModal">X</i>
		<section v-if="user_id">
			<div class="jobs_table sp">
				<div class>
					<section>
						<div class="form_section1">
							<div class="user_type">
								<label class="chk_label">
									<span>User</span>

									<input type="radio" name="usertype" value="0" class="chk_prefered"
										v-model="user_type" :disabled="!is_editable" />
								</label>
								<label class="chk_label">
									<span>Hub Admin</span>
									<input type="radio" name="usertype" value="1" class="chk_prefered"
										v-model="user_type" :disabled="!is_editable" />
								</label>
								<label class="chk_label">
									<span>Customer Service User</span>
									<input type="radio" name="usertype" value="2" class="chk_prefered"
										v-model="user_type" :disabled="!is_editable" />
								</label>
								<label class="chk_label">
									<span>Super Admin</span>
									<input name="usertype" type="radio" class="chk_prefered" value="3" id="user"
										v-model="user_type" :disabled="!is_editable" />
								</label>
							</div>
							<ValidationObserver ref="observer" v-slot="{ invalid }" slim>
								<table>
									<tr>
										<td>
											<ValidationProvider name="employee id" rules="required|max:30"
												v-slot="{ errors }" slim>
												<div class="label_and_element_wrapper">
													<label>
														Employee ID
														<span>*</span>
													</label>
													<input type="text" placeholder="ID Character Limit 30"
														v-model="gl_code.employee_id" :disabled="!is_editable" :maxlength="max2"
														:class="errors[0] ? 'warning-border' : ''" />
												</div>
											</ValidationProvider>
										</td>
										<td>
											<ValidationProvider name="email" rules="required|max:30" v-slot="{ errors }"
												slim>
												<div class="label_and_element_wrapper">
													<label>
														Email
														<span>*</span>
													</label>
													<input type="text" placeholder="Email Character Limit 30"
														v-model="user.email" :disabled="!is_editable" :maxlength="max2"
														:class="errors[0] ? 'warning-border' : ''" />
												</div>
											</ValidationProvider>
										</td>
									</tr>
									<tr>
										<td>
											<ValidationProvider name="full name" rules="required|max:30"
												v-slot="{ errors }" slim>
												<div class="label_and_element_wrapper">
													<label>
														Employee Name
														<span>*</span>
													</label>
													<input type="text" placeholder="Name Character Limit 30"
														v-model="user.name" :disabled="!is_editable" :maxlength="max2"
														:class="errors[0] ? 'warning-border' : ''" />
												</div>
											</ValidationProvider>
										</td>
										<td>
											<ValidationProvider name="branch" rules="required" v-slot="{ errors }" slim>
												<div class="label_and_element_wrapper">
													<label>
														Branch Name
														<span>*</span>
													</label>
													<select v-model="user.branch_id" @change="fetchBranch"
														:disabled="!is_editable"
														:class="errors[0] ? 'warning-border' : ''">
														<option value>Select Branch</option>
														<option v-for="(branch, i) in branches" :key="'branch' + i"
															:value="branch.id">{{ branch.name }}</option>
													</select>
												</div>
											</ValidationProvider>
										</td>
									</tr>
									<tr>
										<td>
											<!-- <ValidationProvider name="office" rules="required|max:30"
												v-slot="{ errors }" slim> -->
												<div class="label_and_element_wrapper">
													<label>
														Office
														<span>*</span>
													</label>
													<select name="" v-model="gl_code.office">
														<option v-for="office in glCodeReferences.offices" :value="office.code">{{ office.name }} ({{ office.code }})</option>
													</select>
													<!-- <input type="text" placeholder="Office Character Limit 30"
														v-model="user.office" :disabled="!is_editable" :maxlength="max2"
														:class="errors[0] ? 'warning-border' : ''" /> -->
												</div>
											<!-- </ValidationProvider> -->
										</td>
										<td>
											<!-- <ValidationProvider name="entity" rules="required|max:30"
												v-slot="{ errors }" slim> -->
												<div class="label_and_element_wrapper">
													<label>
														Entity
														<span>*</span>
													</label>
													<select name="" v-model="gl_code.entity">
														<option v-for="entity in glCodeReferences.entities" :value="entity.code">{{ entity.name }} ({{ entity.code }})</option>
													</select>
													<!-- <input type="text" placeholder="Entity Character Limit 30"
														v-model="user.entity" :disabled="!is_editable" :maxlength="max2"
														:class="errors[0] ? 'warning-border' : ''" /> -->
												</div>
											<!-- </ValidationProvider> -->
										</td>
									</tr>
									<tr>
										<td>
											<!-- <ValidationProvider name="department" rules="required|max:30"
												v-slot="{ errors }" slim> -->
												<div class="label_and_element_wrapper">
													<label>
														Department
														<span>*</span>
													</label>
													<!-- <input type="text" placeholder="Department Character Limit 30"
														v-model="user.department" :disabled="!is_editable" :maxlength="max2"
														:class="errors[0] ? 'warning-border' : ''" /> -->
													<select name="" v-model="gl_code.department">
														<option v-for="department in glCodeReferences.departments" :value="department.code">{{ department.name }} ({{ department.code }})</option>
													</select>
												</div>
											<!-- </ValidationProvider> -->
										</td>
										<td>
											<!-- <ValidationProvider name="section" rules="required|max:30"
												v-slot="{ errors }" slim> -->
												<div class="label_and_element_wrapper">
													<label>
														Section
														<span>*</span>
													</label>
													<select name="" v-model="gl_code.section">
														<option v-for="section in glCodeReferences.sections" :value="section.code">{{ section.name }} ({{ section.code }})</option>
													</select>
													<!-- <input type="text" placeholder="Section Character Limit 30"
														v-model="user.section" :disabled="!is_editable" :maxlength="max2"
														:class="errors[0] ? 'warning-border' : ''" /> -->
												</div>
											<!-- </ValidationProvider> -->
										</td>
									</tr>
									<tr>
										<!-- <td>
											<ValidationProvider name="department" rules="required" v-slot="{ errors }"
												slim>
												<div class="label_and_element_wrapper">
													<label>
														Department
														<span>*</span>

													</label>
													<select v-model="user.department_id" :disabled="!is_editable"
														@change="fetchCostDepartment(0)"
														:class="errors[0] ? 'warning-border' : ''">
														<option value="">Select Department</option>
														<option v-for="(department, i) in departments"
															:key="'delivery_department_name' + i"
															:value="department.department_name">
															{{ department.department_name }}</option>
													</select>
												</div>
											</ValidationProvider>
										</td> -->
										<td>
											<ValidationProvider name="designation" rules="required|max:30"
												v-slot="{ errors }" slim>
												<div class="label_and_element_wrapper">
													<label>
														Designation
														<!-- <span>*</span> -->
													</label>
													<input type="text" placeholder="Designation Character Limit 30"
														v-model="gl_code.designation" :disabled="!is_editable" :maxlength="max2"
														:class="errors[0] ? 'warning-border' : ''" />
												</div>
											</ValidationProvider>
										</td>
										<td>
											<ValidationProvider name="floor" rules="required|max:60" v-slot="{ errors }"
												slim>
												<div class="label_and_element_wrapper">
													<label>
														Floor Number
														<!-- <span>*</span> -->
													</label>
													<input type="text" placeholder="Floor Number Character Limit 60"
														v-model="gl_code.floor" :maxlength="max" :disabled="!is_editable"
														:class="errors[0] ? 'warning-border' : ''" />
												</div>
											</ValidationProvider>
										</td>
									</tr>
									<tr>
										<td>
											<ValidationProvider name="desk" rules="required|max:60" v-slot="{ errors }"
												slim>
												<div class="label_and_element_wrapper">
													<label>
														Area
														<!-- <span>*</span> -->
													</label>
													<input type="text" placeholder="Area Character Limit 60"
														v-model="gl_code.area" :maxlength="max" :disabled="!is_editable"
														:class="errors[0] ? 'warning-border' : ''" />
												</div>
											</ValidationProvider>
										</td>
										<td>
											<ValidationProvider name="desk" rules="required|max:60" v-slot="{ errors }"
												slim>
												<div class="label_and_element_wrapper">
													<label>
														Desk Number
														<span>*</span>
													</label>
													<input type="text" placeholder="Desk Number Character Limit 60"
														v-model="user.desk" :maxlength="max" 
														disabled
														:class="errors[0] ? 'warning-border' : ''" />
												</div>
											</ValidationProvider>
										</td>
									</tr>
									<tr>
										<!-- <td>
											<ValidationProvider name="cost" rules="required" v-slot="{ errors }" slim>
												<div class="label_and_element_wrapper">
													<label>
														Cost Center
														<span>*</span>
													</label>
													<select v-model="user.cost_center_id"
														:class="errors[0] ? 'warning-border' : ''"
														:disabled="!is_editable">
														<option value="" disabled>Select Cost center</option>
														<option v-for="(data, i) in costs" :key="'data' + i"
															:value="data.id">{{ data.name }}</option>
													</select>
													//
													<select v-model="user.cost_center_id" :disabled="!is_editable" :class="errors[0] ? 'warning-border':''">
														<option value="" disabled>Select Cost center</option>
														<option :value="costs.id" >{{costs.name}}</option>  
														</select> 
													//
												</div>
											</ValidationProvider>
										</td> -->

										<td>
											<ValidationProvider name="address_1" rules="required|max:60"
												v-slot="{ errors }" slim>
												<div class="label_and_element_wrapper">
													<label>
														Address 1
														<span>*</span>
													</label>
													<input type="text" placeholder="Address 1 Character Limit 60"
														disabled v-model="branch_data.address_1" :maxlength="max"
														:class="errors[0] ? 'warning-border' : ''" />
												</div>
											</ValidationProvider>
										</td>
										<td>
											<ValidationProvider name="address_2" rules="required|max:60"
												v-slot="{ errors }" slim>
												<div class="label_and_element_wrapper">
													<label>
														Address 2
														<span>*</span>
													</label>
													<input type="text" placeholder="Address 2 Character Limit 60"
														disabled v-model="branch_data.address_2" :maxlength="max"
														:class="errors[0] ? 'warning-border' : ''" />
												</div>
											</ValidationProvider>
										</td>
									</tr>
									<tr>
										<td>
											<ValidationProvider name="city" rules="required|max:30" v-slot="{ errors }"
												slim>
												<div class="label_and_element_wrapper">
													<label>
														City
														<span>*</span>
													</label>
													<input type="text" placeholder="City Character Limit 30" disabled
														v-model="branch_data.city" :maxlength="max2"
														:class="errors[0] ? 'warning-border' : ''" />
												</div>
											</ValidationProvider>
										</td>
										<td>
											<ValidationProvider name="state" rules="required|max:30" v-slot="{ errors }"
												slim>
												<div class="label_and_element_wrapper">
													<label>
														State
														<span>*</span>
													</label>
													<input type="text" placeholder="State Character Limit 30" disabled
														v-model="branch_data.state" :maxlength="max2"
														:class="errors[0] ? 'warning-border' : ''" />
												</div>
											</ValidationProvider>
										</td>
									</tr>
									<tr>
										<td>
											<div class="label_and_element_wrapper">
												<label>
													Suburb
												</label>
												<input type="text" placeholder="Suburb Character Limit 30" disabled
													v-model="branch_data.suburb" :maxlength="max2" />
											</div>
										</td>
										<td>
											<ValidationProvider name="postal_code" rules="required|max:10"
												v-slot="{ errors }" slim>
												<div class="label_and_element_wrapper">
													<label>
														Postal Code
														<span>*</span>
													</label>
													<input type="text" placeholder="Postal Code Character Limit 10"
														disabled v-model="branch_data.postal_code" :maxlength="max1"
														:class="errors[0] ? 'warning-border' : ''" />
												</div>
											</ValidationProvider>
										</td>
									</tr>
									<tr>
										<td>
											<ValidationProvider name="country" rules="required|max:30"
												v-slot="{ errors }" slim>
												<div class="label_and_element_wrapper">
													<label>
														Country
														<span>*</span>
													</label>
													<input type="text" placeholder="Country Character Limit 30" disabled
														v-model="branch_data.country" :maxlength="max2"
														:class="errors[0] ? 'warning-border' : ''" />
												</div>
											</ValidationProvider>
										</td>
										<td>
											<ValidationProvider name="phone" rules="required|max:20" v-slot="{ errors }"
												slim>
												<div class="label_and_element_wrapper">
													<label>
														Phone
														<span>*</span>
													</label>
													<input type="text" placeholder="Phone Character Limit 20" disabled
														v-model="branch_data.phone" :maxlength="max4"
														:class="errors[0] ? 'warning-border' : ''" />
												</div>
											</ValidationProvider>
										</td>
									</tr>
								</table>
							</ValidationObserver>
						</div>
					</section>

					<section class="centered">
						<a class="link_bt bt_cancel" @click="closeModal">CANCEL</a>
						<a class="link_bt bt_save" @click="updateUser" :disabled="update_btn.disabled"
							v-if="is_editable">{{ update_btn.value }}</a>
					</section>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
export default {
	name: "AddUser",
	data() {
		return {
			max: 60,
			max2: 30,
			max1: 10,
			max4: 20,
			user: {
				is_admin: 0,
				is_super_admin: 0,
				is_service_user: 0,
				name: "",
				email: "",
				password: "",
				branch_id: "",
				cost_center_id: "",
				department_id: "",
				office:'',
				entity:'',
				department:'',
				section:'',
				designation:'',
				floor:'',
				area:'',
				desk:'',
			},
			gl_code: null,
			user_type: "",
			branches: [],
			cost_centers: [],
			costs: [],
			departments: [],
			branch_data: {
				address_1: "",
				address_2: "",
				city: "",
				state: "",
				suburb: "",
				postal_code: "",
				country: "",
				phone: ""
			},
			update_btn: {
				disabled: false,
				value: "UPDATE"
			},
			glCodeReferences: {
				departments:[],
				companies:[],
				entities:[],
				offices:[],
				sections:[],
			},
		};
	},
	async created() {
		await this.fetchUser();
		await this.fetchBranches();
		// this.fetchCost();
		await this.fetchDepartment();
		await this.fetchGlCodeReferences()
	},
	methods: {
		async fetchGlCodeReferences() {
			try {
				const response = await this.axios.get("api/glcode/references")
				this.glCodeReferences = response.data.gl_codes
				console.log(this.glCodeReferences)
			} catch (e) {

			}
		},
		closeModal() {
			this.$emit("closeModal");
		},
		fetchUser() {
			this.axios.get("api/user/" + this.user_id)
				.then(response => {
					this.user = response.data.msg;
					if (this.user.is_admin == 0 && this.user.is_super_admin == 0 && this.user.is_service_user == 0) {
						this.user_type = 0;
					} else if (this.user.is_admin == 1 && this.user.is_super_admin == 0 && this.user.is_service_user == 0) {
						this.user_type = 1;
					} else if (this.user.is_admin == 1 && this.user.is_super_admin == 1 && this.user.is_service_user == 1) {
						this.user_type = 2;
					} else if (this.user.is_admin == 1 && this.user.is_super_admin == 1 && this.user.is_service_user == 0) {
						this.user_type = 3;
					}
					this.user.department_id = response.data.msg.department_id;
					this.user.cost_center_id = response.data.msg.cost_center_id;
					this.gl_code = this.user.gl_code
					this.fetchCostDepartment(response.data.msg.cost_center_id);
					this.fetchBranch();
				})
				.catch(error => {
					console.log(error);
				});
		},
		fetchBranches() {
			this.axios
				.get("/api/branch/view")
				.then(response => {
					this.branches = response.data.branches;
				})
				.catch(error => {
					this.toast.error();
				});
		},

		fetchDepartment() {

			this.axios.get("/api/department/view")
				.then(response => {
					this.departments = response.data.department;
				})
				.catch(error => {
					this.toast.error();
				});

		},
		// fetchDepartment(){
		//   if(this.user.branch_id != ''){
		//     this.axios.get("/api/location/showdepartment/"+this.user.branch_id)
		//     .then(response => {
		//       this.departments = response.data.department;
		//       /*if(this.departments.length > 0){
		//         this.user.department_id = this.departments[0].department_name;
		//       }*/
		//     })
		//     .catch(error => {
		//       this.toast.error();
		//     });
		//   }
		// },
		fetchBranch() {
			this.axios
				.get("/api/branch/view/branchId/" + this.user.branch_id)
				.then(response => {
					this.branch_data.address_1 = response.data.branches.address_1;
					this.branch_data.address_2 = response.data.branches.address_2;
					this.branch_data.city = response.data.branches.city;
					this.branch_data.state = response.data.branches.state;
					this.branch_data.suburb = response.data.branches.suburb;
					this.branch_data.postal_code = response.data.branches.postal_code;
					this.branch_data.country = response.data.branches.country;
					this.branch_data.phone = response.data.branches.phone;
				})
				.catch(error => {
					console.log(error);
				});
		},

		fetchCostDepartment($id) {
			if (this.user.department_id != '') {
				this.axios.get("/api/cost/view/" + this.user.department_id)
					.then(response => {
						this.costs = response.data.cost_centers;
						if (this.costs.length > 0) {
							if ($id == 0) {
								this.user.cost_center_id = this.costs[0].id;
							}
						}
					})
					.catch(error => {
						console.log(error);
					});
			}
		},
		// fetchCost(){
		//   this.axios.get("/api/cost")
		//   .then(response => {
		//     this.cost_centers = response.data.cost_centers;
		//   })
		//   .catch(error => {
		//     console.log(error);
		//   });
		// },
		setUserType() {
			if (this.user_type == 0) {
				this.user.is_admin = 0;
				this.user.is_super_admin = 0;
				this.user.is_service_user = 0;
			} else if (this.user_type == 1) {
				this.user.is_admin = 1;
				this.user.is_super_admin = 0;
				this.user.is_service_user = 0;
			} else if (this.user_type == 2) {
				this.user.is_admin = 1;
				this.user.is_super_admin = 1;
				this.user.is_service_user = 1;
			} else if (this.user_type == 3) {
				this.user.is_admin = 1;
				this.user.is_super_admin = 1;
				this.user.is_service_user = 0;
			}
		},
		updateUser() {
			this.setUserType();
			this.update_btn.disabled = true;
			this.update_btn.value = "UPDATING...";
			this.user.form_gl_code = this.gl_code
			this.axios.put("api/user/" + this.user_id, this.user)
				.then(response => {
					this.toast.success(response.data.msg);
					this.update_btn.disabled = false;
					this.update_btn.value = "UPDATE";
					this.closeModal();
				})
				.catch(error => {
					this.update_btn.disabled = false;
					this.update_btn.value = "UPDATE";
					console.log(error);
				});
		}
	},
	props: {
		user_id: String,
		is_editable: {
			type: Boolean,
			default: true
		}
	},
	watch: {
		// 'user.branch_id'(){
		//   this.fetchDepartment();
		// }
	}
};
</script>
<style lang="scss">
.popup_sub_container {
	padding: 20px;
	width: 100%;
	margin: auto;

	background: #fff;
}

.popup_sub_container>section {
	padding: 20px 0 0 0;
}

h3.sub_head {
	font-size: 20px;
	font-weight: 600;
	padding: 0;
}

.preferred_hide {
	display: none;
}

.chk_prefered {
	width: 20px;
	height: 20px;
	display: inline-block;
	margin: 0 20px;
}

.chk_prefered:checked+hr+.preferred_hide {
	display: block;
}

.title_image {
	display: block;
	margin: auto;
}

.form_section1 {
	width: 100%;
	height: auto;
	padding: 20px;
	background: #ececec;
	margin: 20px 0;
}

i.closeme {
	width: 30px;
	height: 30px;
	background: #000;
	color: #06A5ED;
	font-size: 14px;
	text-align: center;
	line-height: 30px;
	border-radius: 100px;
	display: block;
	font-style: normal;
	font-weight: bold;
	position: absolute;
	right: 20px;
	top: 20px;
	cursor: pointer;
}
</style>
<style scoped>
.jobs_table table {
	border: 0;
}

.jobs_table table tr td {
	padding: 0;
	border: 0;
}
</style>